.login{
	display: flex;
}
/* Reset font colours */
.login h1{
	color: white;
	font-weight: 400;
}

.login h2,
.login h3,
.login h6,
.login p{
	color: black;
	font-weight: 100;
}
.login h1{
	font-size: 62px;
}
.login h2{
	font-size: 48px;
}
.login h3{
	font-size: 24px;
}
.login p{
	font-size: 16;
	color: grey;
}

.login-form{
	position: absolute;
	width: 40%;
	height: 100%;
	top: 0;
	left: 0;
}

.login-form-content{
	position: absolute;
	top: 50%;
	left: 65%;
	width: 45%;
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}
.input-container{
	padding-bottom: 25px;
}

.login-splash{
	position: absolute;
	background: url(../utils/pictures/login-bg.png) no-repeat center center/ cover;
	width: 60% !important;
	height: 100%;
	top: 0;
	right: 0;
}

.login-splash-content{
	position: absolute;
	top: 50%;
	left: 48%;
	width: 75%;
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}
.login-splash-content h1{
	/* font-family: 'Balsamiq Sans', cursive !important; */
	font-family: 'Lato', sans-serif;
	padding-left: 50px;
}

.divider:after,
.divider:before {
  content: "";
  flex: 1;
  height: 1px;
  background: #eee;
}
.MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -0.8px) scale(0.75) !important;
}
#ResetButton{
	margin-top: 0% !important; 
	margin-left: 190px !important; 
}
@media (max-width: 630px){

	.login-splash{
		width: 180px !important;
		height: 100% !important;
	}
	.login-splash-content h1{
		font-size: 34px !important;
		margin-left: -49px
	}
	.login-form{
		width: 52% !important;
	}
	.login-form-content{
		width: 85% !important;
		margin-left: -30px !important;
	}
	#forgot-password {
		width: 180px !important;
		margin-top: -35px !important;
		margin-left: 48px !important;
	}
	.MuiButton-label{
		font-size: 12px;
	}
	#backButton{
		margin-left: 20px !important;
	}
	#LoginButton{
		padding-top: 50px;
		margin-left: -35px !important;
		max-width: 85px;
	}
	.divider{
		margin-top: 49px !important;
	}
	.text-center{
		margin-left: 15px !important;
	}
	.MuiSvgIcon-root{
		display:none !important;
	}
	.MuiFormLabel-root{
		font-size: 14px !important;
	}
	
}
@media screen and (min-width: 600px) and (max-width: 1500px) {
	#SubmitButton{
		margin-left: -80px !important;
	}
	#ResetButton{
		margin-top: -63px !important;
		margin-left: 120px !important;
	}
	#LoginButton{
		margin-top: -70px!important;
	}
}
/* @media screen and (max-device-height: 950px ){
	.login-splash-content h1{
		font-size: 50px!important;
	}
} */

@media screen and (max-device-height: 1400px ) and (max-device-width: 500px){
	
	#LoginButton{
		margin-top: -70px!important;
	}
	#SubmitButton{
		margin-left:-139px !important
	}
	#ResetButton{
		margin-top: -56px;
		margin-left: 100px !important; 
	}
	.login-splash {
		width:45%;
	}
	.input-container{
		margin-left:15px;
	}
	
	/* .login-splash-content h1{
		font-size: 50px!important;
	} */
}