.backdrop{

  height: 10% !important;

}
.k-card{
  /* width: 100% !important; */
}

.chartDiv{
  height: 700px;
  overflow: auto;
  overflow-x: hidden !important;
  /* background-color: white; */
}
.chartDiv .row h1{
  padding-top: 1.5%;
}
.myPartDiv{
  height: 700px
}

.col-6{
  --bs-gutter-y: 1.5rem !important;
}
pre{
  height: 600px ;
  overflow-y: auto !important;
  overflow-x: hidden;
  word-break: normal !important;
  word-wrap: normal !important;
  white-space:pre-wrap !important;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px !important;
}
.box{
  overflow: hidden;
  position: relative;
  padding:20px;
}
h2{
  font-weight:600;
  font-size: 1.7rem;
}
.col-md-4{
  height: 25.5rem;
}
.box:hover .info-content{
  display: block !important;
}
.btn-link:hover{
  color: red !important
}
.box:hover img{
  -webkit-filter: blur(0px);
  filter: blur(0px);
  opacity: 18%;
}
.box{
  padding-left: 12px!important;
  padding-right: 12px!important;
  margin-bottom: -15px; 
  background: #fff;
}
.box img{
  max-width: 100%;
  transition: .5s ease;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  border: 2px solid black !important;
  box-shadow: 3px 3px 4px grey;
}
.info-content{
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  padding: 20px;
  color:#000; 
}
.link-container{
  text-decoration: none;
  color:#000;
  font-weight: 500;
  width: 100%;
  align-self: center;
}
.center {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.btn-link{
  text-decoration: none!important;
  color:#000 ; /*overriden by API color */
  font-weight: 650!important;
  font-size: 18px !important;
  
}
.btn-link:focus {
  box-shadow:none!important;
}
.hidden{
  display: none;
}
#titlePP{
  margin-bottom:-0.3%;
  color: #e4191d;
}
video{
  height: 600px !important;
}

/*============Button Css for Menu============*/
.menuButton {
  display: block;
  padding: 12px 20px;
  border: unset;
  border-radius: 15px;
  color: #212121;
  z-index: 1;
  background: #e8e8e8;
  position: relative;
  font-weight: 700;
  font-size: 17px;
  -webkit-box-shadow: 4px 8px 19px -3px rgba(0,0,0,0.27);
  box-shadow: 4px 8px 19px -3px rgba(0,0,0,0.27);
  transition: all 250ms;
  overflow: hidden;
 }
 
 .menuButton::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  /* border-radius: 15px; */
  background-color: #212121;
  z-index: -1;
  -webkit-box-shadow: 4px 8px 19px -3px rgba(0,0,0,0.27);
  box-shadow: 4px 8px 19px -3px rgba(0,0,0,0.27);
  /*transition: all 250ms*/
 }
 
 .menuButton:hover {
  color: #e8e8e8;
 }
 
 .menuButton:hover::before {
  width: 100%;
 }
/*===========================================*/
/* @media (max-width: 768px){ */
@media (max-width: 1200px){

  
  .box {
    width: 100% !important;
    height: 80% !important;
    /* margin-left: 18px; */
  }
  .col-6{
    width: 100%
  }
  .chart{
    width: 100% !important
  }
  
  .info-content .title{
    font-size: 35px;
    /* padding-left: 38px; */
  }
  .menuButton{
    font-size: medium !important;
    margin: 10px;
    padding: 12px;
  }
  .description > h5{
    font-size: 20px !important;
    padding-left: 25px;
  }
   #videoWindow{
    /* margin-left: -10% !important; */
    margin-top: 120px !important;
    /* width: 0 !important; */
  }
  video{
    /* margin-left: 13px; */
    /* width: 515px !important; */
    margin-top: -5px;
    margin-bottom: -5px;
  }
  .sDesc{
    /* margin-left: 33px !important; */
    /* background-color: rgba(255, 255, 255, .6); */
    margin-bottom: 25px;
  }
  .login-form-content{
    margin-left: -27px !important;
  }
  .flex-container-horizontal{
    width: 140px;
    margin-left:-30px;
  }
  #LoginButton{
    margin-top: -70px !important;
    padding-left: 45px
  }
  
  #SubmitButton{
    margin-left: -120px !important;
  }
  .MuiSvgIcon-root{
    display: none !important;
  }
  .MuiOutlinedInput-input{
    padding-bottom: 25px !important;
  }
  
}
@media screen and (min-device-width: 2070px) /*and (min-width: 1100px)*/{
  #titlePP{
    /* margin-left:38% ; */
    font-size: 63px;

  }
}
/*===========================================*/

  
@media screen and (max-device-height: 1000px ) and (max-width: 660px){
 
  #videoWindow{
    /* margin-left: 3% !important; */
    margin-top: 30px !important;
    width: 110% !important;
    height: 147px !important;
  }
  .py-3{
    padding:0 !important;

  }
  video{
    /* margin-left: -50px; */
    /* width: 265px !important; */
    margin-top: -10px;
    height: 137px !important;
  }
  .description{
    margin-bottom: 0px !important;
  }
  .box img{
    /* height: 220px; */
    /* width: 97.8%; */
    width: 100%;
  }
  
  .modal-header{
    height: 60px;
  }
  .modal-title{
    font-size: 20px !important;
  }
  .sDesc{
    font-size: 14px !important;
    margin-bottom: 35px;
  }
  .link-container{
    display: flex;
    margin-top:0% !important
  }
  .btn-close{
    margin-top: 19px !important;
    margin-right: -20px;
  }
  .menuButton{
    font-size: small !important;
    margin: 10px;
    padding: 2px;
  }
  .info-content .title{
    font-weight: 700;
    font-size: 22px !important;
  }
  .info-content .description h5{
    font-size: 16px !important;
  }
  .btn-link{
    font-size: 16px !important;
  }
  #titlePP{
    margin-left:-1% !important;
    margin-bottom: -1%;
    font-size: 36px !important; 
  }
  
  .cardContBox{
    width:80% !important;
    margin-left: 9% !important;
  }
  .cardCont{
    height: 100% !important;
  }
  .cardContText{
    font-size: 15px !important;
  }
  .k-card h2{
    font-size: 0.8rem;
  }
  .row h1{
    font-size: 1.4rem;
    padding-top: 3%;
    margin-bottom: -3%;
  }
  pre{
    margin-top: -55px;
    height: 415px !important;
    font-size: 13px !important;

  }
  .myPartDiv{
    height: 475px;
    overflow: auto;
  }
  .chartDiv{
    height: 475px;
    overflow: auto;
    overflow-x: hidden !important;
  }
  .modal-content{
    height: 95% !important;
  }
}
/* LAPTOP VIEW */
@media screen and (min-device-width: 1200px ) and /*(max-height: 785px)*/(max-height: 801px){
  pre{
    font-size: 14px !important;
    height: 325px !important;
  }
  .link-container{
    margin-bottom: 5px;
  }
  .modal-body{
    height:0% !important; 
  }
  .col-md-4{
    height: 340px ;
  }
  #videoWindow{
    margin-top: 1.8% !important; 
    height:370px !important;
    width: 685px !important;
  }
  video{
    margin-top: -5px;
    /* height: 100% !important; */
    height: 350px !important;
  }
  .myPartDiv{
    height:413px;
    overflow: auto;
  }
  
  .chartDiv{
    height:413px;
    overflow: auto;
    overflow-x: hidden !important;

  }
  .chartDiv h1{
    font-size: 2rem;
  }
  .chartDiv h2{
    font-size: 1.5rem;
  }
  .cardCont{
    height: 100%;    
    padding:3% !important;
    object-fit: contain;
  }
}

@media screen and (min-device-width: 1200px ) and (min-height: 786px) and (max-height: 970px){
  #videoWindow{
    margin-top: 1.8% !important; 
    width: 880px !important;
  }
  video{
    margin-top: -5px !important;
    /* height: 100% !important; */
    height: 510px !important;

  }
  .py-3{
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .py-lg-5{
    padding-top: 17px !important;
    padding-bottom: 5px !important;
  }
  .myPartDiv{
    height: 570px ;
    overflow:auto;
  }
  .chartDiv{
    height: 570px ;
    overflow:auto;
    overflow-x:hidden !important;
  }
  pre{
    height: 480px ;
  }
  
}
@media screen and (min-device-width: 1200px ) and (max-device-width: 1335px ) and (min-height: 786px) and (max-height: 970px){
  pre{
    height: 510px ;
  }
  .myPartDiv{
    height: 610px ;
    overflow:auto;
  }
  .chartDiv{
    height: 610px ;
    overflow:auto;
    overflow-x:hidden !important;
  }

}
@media screen and (min-device-width: 1200px ) and (max-height: 675px){
  pre{
    font-size: 13px !important;
    height: 263px !important;
  }
  #videoWindow{
    width: 60% !important;
    height: 65% !important;
  }
  .myPartDiv{
    height: 350px ;
  }
  .chartDiv{
    height: 350px ;

  }
}
/* @media screen and (min-device-width: 1210px ) and (min-device-height: 786px ) and (max-height: 1050px){
  pre{
    height: 448px ;
  }
  .modal-body{
    height:10% !important; 
  }
} */

/* @media screen and (min-device-width: 760px) and (max-device-width: 800px){ */
  @media screen and (min-device-width: 760px) and (max-device-width: 1400px) and (min-device-height: 780px){
    #videoWindow{
    margin-top: 120px !important; 
    height:320px !important;
    height:320px !important;
    width: 535px !important;
  }
  video{
    margin-top: -8px;
    height: 310px !important;
    width:32rem !important;    
    
  }
  pre{
    height: 383px !important;
    font-size: 15px !important;
    position: relative !important;
  }
  .myPartDiv{
    height: 470px ;
  }
  .chartDiv{
    height: 470px ;

  }
  .title{
    font-size: 27px;
  }
}
@media screen and (min-device-width: 660px) and (max-device-width: 759px) /*and (min-device-height: 780px)*/{
  
  pre{
    height: 380px !important;
    font-size: 16px !important;
    position: relative !important;
  }
  .myPartDiv{
    height: 600px ;
  }
  .chartDiv{
    height: 600px ;
  }
  .sDesc{
    font-size: 23px !important;
  }
  
}

@media screen and (min-device-width: 800px) and (max-device-width: 1200px) and (min-device-height: 1050px){


  pre{
    height: 595px !important;
    font-size: 16px !important;
    position: relative !important;
  }
  .myPartDiv{
    height: 689px ;
  }
  .chartDiv{
    height: 689px ;

  }
}
@media screen and (min-device-width: 370px) and (max-device-width: 380px) and (min-device-height: 810px) and (max-device-height: 820px){


  pre{
    height: 390px !important;
    font-size: 13px !important;
    position: relative !important;
  }
  .myPartDiv{
    height: 465px ;
  }
  .chartDiv{
    height: 465px ;

  }
}

@media screen and (min-device-width: 1275px) and (max-device-width: 1285px) and (min-device-height: 945px) and (max-device-height: 955px){

  pre{
    height: 480px !important;
    font-size: 15px !important;
    position: relative !important;
  }
  .myPartDiv{
    height: 567px ;
  }
  .chartDiv{
    height: 567px ;
  }

}

  @media screen and  (max-device-width: 510px) and (min-device-height: 720px) and (max-device-height: 800px){

  pre{
    height: 450px !important;
    font-size: 13px !important;
    position: relative !important;
  }
  .myPartDiv{
    height: 490px ;
  }
  .chartDiv{
    height: 490px ;
  }
  #videoWindow{
    /* height: 125px !important */
    height: 150px !important
  
  }
  .modal-content{
    height: 95% !important;
  }
  .recharts-surface{
    /* width:310px !important; */
    margin-top: -40px;
    width:62% !important;
    margin-left: 70px;
  }
  .lineChartCss{
    width: 185% !important;
    margin-left: -90px !important;
  }
}
  @media screen and (max-device-width: 510px)  and (max-device-height: 719px){
  

    pre{
      height: 410px !important;
      font-size: 13px !important;
      position: relative !important;
    }
    .myPartDiv{
      height: 450px ;
    }
    .chartDiv{
      height: 450px ;
    }
    #videoWindow{
      height: 150px 
    }
    .modal-content{
      height: 95% !important;
    }
  }
  @media screen and (max-device-width: 510px) and (max-device-height: 673px){
  

    pre{
      height: 350px !important;
      font-size: 13px !important;
      position: relative !important;
    }
    .myPartDiv{
      height: 390px ;
    }
    .chartDiv{
      height: 390px ;
    }
    #videoWindow{
      height: 150px 
    }
    .modal-content{
      height: 95% !important;
    }
  }

  @media screen  and (min-device-height: 801px) and (max-device-width: 510px) /*and (max-device-height: 900px)*/{
  

    pre{
      
      height: 460px !important;
      font-size: 13px !important;
      position: relative !important;
    }
    .myPartDiv{
      height: 500px ;
    }
    .chartDiv{
      height: 500px ;
    }
    #videoWindow{
      height: 150px 
    }
    .modal-content{
      height: 95% !important;
    }
    .recharts-surface{
      /* width:310px !important; */
      margin-top: -40px;
      width:59% !important;
      margin-left: 78px;
    }
    .lineChartCss{
      width: 190% !important;
      margin-left: -90px !important;
    }
  }


  
